@import url(https://fonts.googleapis.com/css2?family=Lat:300o&family=Roboto:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
/* @import url('https://fonts.googleapis.com/css?family=Lato:300'); */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap'); */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Roboto', sans-serif;
	min-height: 100vh;
	background-color: #101114;
}

.hidden {
	display: none;
}

.not-found {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: #151320;
}

.not-found h1 {
	font-size: 10em;
}

.not-found p {
	font-size: 4em;
}

div#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main {
	flex-grow: 1;
	display: flex;
}

main>div {
	justify-self: stretch;
	display: flex;
	flex-direction: column;
}

/* .loading {
	height: 100vh;
	width: 100vw;
	color: white;
	font-size: 7em;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #22212c;
} */

header {
	height: 100vh;
}

.intro {
	display: flex;
	justify-content: center;
	align-items: center;
	max-height: 100vh;
	height: 100%;
}

.intro img {
	height: 100%;
	width: 100%;
	/* object-fit: cover; */
}

.me {
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.me i {
	color: rgba(240, 193, 9, 1);
	font-size: 5vw;
	left: 0;
	text-shadow: 3px 3px 20px #ff99cc, -2px 1px 30px #ff99cc;
}

nav {
	width: 100%;
	/* background: rgba(105, 105, 105, 0.205); */
	background-color: #101114;
	height: 4em;
}

nav, nav>ul {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	list-style: none;
}

nav li {
	/* color: rgba(240, 193, 9, 1); */
	margin-left: 2em;
	margin-right: 2em;
}

nav a {
	width: 100%;
	text-decoration: none;
	color: white;
	position: relative;
	font-size: 1.1em;
}

nav li>a::after, footer div:first-of-type a::after {
	background: none repeat scroll 0 0 transparent;
	bottom: 0;
	content: '';
	display: block;
	height: 2px;
	left: 50%;
	position: absolute;
	background: white;
	transition: width 0.3s ease 0s, left 0.3s ease 0s;
	width: 0;
}

nav a:hover {
	color: dodgerblue;
	transition: color 0.6s ease;
}

nav a:hover::after, footer div:first-of-type a:hover::after {
	width: 100%;
	left: 0;
}

footer {
	width: 100%;
	padding: 1em;
	background-color: #101114;
	display: flex;
	justify-content: center;
	align-items: center;
}

footer div:first-of-type a {
	color: dodgerblue;
	font-size: 1.1em;
	text-decoration: none;
	position: relative;
}

footer div span {
	/* color: rgba(240, 193, 9, 1); */
	color: white;
}

footer i {
	font-size: 1.3em;
	margin: 0.2em;
}

.social-links {
	display: flex;
}

.social-links a {
	color: white;
	text-decoration: none;
	border: 0.2em solid white;
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 1.5em;
}

.social-links a:hover {
	border-color: dodgerblue;
}

.lg-box {
	/* background: linear-gradient(brown, rgba(240, 193, 9, 1), rgb(63, 1, 27)); */
	/* background: linear-gradient(#6D120D, rgb(177, 26, 18), #6D120D); */
	background: linear-gradient(#151320, #6d120d, #151320);
	background-repeat: no-repeat;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	color: white;
	font-size: 1.1rem;
}

.lg-box a {
	text-decoration: none;
	color: dodgerblue;
	position: relative;
	margin: 1em 0.5em 0 0.5em;
}

.lg-box a::after {
	content: '';
	background: white;
	mix-blend-mode: exclusion;
	width: calc(100% + 20px);
	height: 0;
	position: absolute;
	bottom: -4px;
	left: -10px;
	transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.lg-box a:hover {
	color: white;
	transition: color 0.6s ease;
}

.lg-box a:hover::after {
	height: calc(100% + 8px);
}

.container {
	margin: 0 0 0 2em;
	width: 5em;
	height: 4em;
	display: flex;
	justify-content: center;
	align-items: center;
	/* place-content: center;
    overflow: scroll; */
	background: rgba(105, 105, 105, 0.205);
	border-radius: 30px;
}

.svg-item {
	width: 80%;
	align-self: center;
	justify-self: center;
	overflow: hidden;
	/* stroke: #fff; */
	stroke: rgb(177, 26, 18);
	stroke-width: 2;
	stroke-linejoin: round;
	stroke-linecap: round;
}

.items-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1em;
}

.items-wrapper .item {
	/* background-color: dimgray; */
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 2em;
	margin-top: 2em;
}

.items-wrapper .row {
	width: 100%;
}

.items-wrapper p {
	max-width: 100%;
}

svg.name {
	/* transform: translate(-50%, -50%); */
	width: 50vw;
}

svg text {
	text-transform: uppercase;
	font-size: 5vw;
	stroke-width: 6;
	stroke: rgb(255, 208, 19);
	/* #4b6cc1 */
	fill: rgb(255, 208, 19);
	/* fill: #4b6cc1; */
	-webkit-animation: stroke 8s ease;
	        animation: stroke 8s ease;
	/* text-shadow: 3px 3px 20px #ff99cc, -2px 1px 30px #ff99cc; */
	text-shadow: 16px 22px 11px rgba(168, 158, 32, 0.8);
}

@-webkit-keyframes stroke {
	0% {
		fill: rgba(240, 193, 6, 0);
		stroke-dashoffset: 25%;
		stroke-dasharray: 0 35%;
		stroke-width: 6;
		stroke: #f0c109;
	}
	20% {
		stroke: #f09f09;
	}
	30% {
		stroke: rgb(202, 70, 9);
	}
	50% {
		stroke: #e07b07;
	}
	70% {
		fill: rgba(240, 193, 7, 0);
	}
	80% {
		fill: rgba(240, 193, 8, 0);
		stroke-width: 5;
		stroke: #97f009;
	}
	90% {
		fill: rgb(255, 208, 19);
		stroke-width: 5.5;
	}
	100% {
		fill: rgb(255, 208, 19);
		stroke-dasharray: 50% 0;
		stroke-width: 6;
	}
}

@keyframes stroke {
	0% {
		fill: rgba(240, 193, 6, 0);
		stroke-dashoffset: 25%;
		stroke-dasharray: 0 35%;
		stroke-width: 6;
		stroke: #f0c109;
	}
	20% {
		stroke: #f09f09;
	}
	30% {
		stroke: rgb(202, 70, 9);
	}
	50% {
		stroke: #e07b07;
	}
	70% {
		fill: rgba(240, 193, 7, 0);
	}
	80% {
		fill: rgba(240, 193, 8, 0);
		stroke-width: 5;
		stroke: #97f009;
	}
	90% {
		fill: rgb(255, 208, 19);
		stroke-width: 5.5;
	}
	100% {
		fill: rgb(255, 208, 19);
		stroke-dasharray: 50% 0;
		stroke-width: 6;
	}
}

/* HomeScreen */

.home {
	height: auto;
}

.home .md-container {
	margin-top: 2em;
	width: 70%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	/* background-color: rgba(212, 48, 48, 0.623); */
	/* background-color: #191a1d; */
	background-color: #22212c;
	padding: 2em;
	border-radius: 1em;
}

.home .sm-container, .home .sm-container>p {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.home .avatar {
	min-width: 25%;
	padding: 0 1em;
	margin-right: 2em;
}

.avatar>img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: dimgray;
}

.home .contact {
	width: 70%;
	margin-bottom: 3em;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #850c06;
}

.home .contact i {
	font-size: 1.5em;
	color: dodgerblue;
	margin-right: 0.5em;
}

.home .portfolio {
	width: 80%;
	max-width: 95%;
	margin: 2em 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	/* background-color: rgba(212, 48, 48, 0.623); */
	/* background-color: #191a1d; */
	background-color: #22212c;
	padding: 1em;
	border-radius: 1em;
	flex-grow: 1;
	color: #999;
}

.portfolio .images-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.portfolio img {
	width: 90%;
	border-radius: 1em;
	margin-bottom: 1.5em;
}

.portfolio h1 {
	align-self: center;
	font-size: 3rem;
	text-decoration: underline;
	color: white;
}

.portfolio h2 {
	font-size: 2rem;
	padding-bottom: 1rem;
	color: white;
}

@media only screen and (max-width:1400px) {
	header {
		height: auto;
	}
}

@media only screen and (max-width: 600px) {
	.home .md-container {
		width: 97%;
		flex-direction: column;
		align-items: center;
	}
	.home .avatar {
		min-width: 40%;
		padding: 0 1em;
		margin-right: 1em;
	}
	.me {
		padding: 1em;
	}
	.me i {
		font-size: 8vw;
	}
	svg.name {
		width: 100%;
		overflow: visible;
		height: 6em;
	}
	svg text {
		font-size: 10vw;
	}
	footer {
		padding: 2em;
	}
	.home .portfolio {
		width: 97%;
	}
}

/* AboutScreen */

.about {
	font-size: 1.25rem;
}

.about-wrapper {
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.about h1 {
	color: white;
	margin-top: 2rem;
}

.about hr {
	background-color: dimgray;
	border-color: rgb(139, 130, 130);
	margin-top: 1em;
	width: 100%;
	height: 0.05rem;
}

.about .avatar {
	min-width: 30%;
}

.about .avatar>img {
	box-shadow: 3px 3px 20px #ff99cc, -2px 1px 30px #ff99cc;
	margin-bottom: 1.5em;
	height: auto;
}

.about .md-container {
	margin: 3em 0 3em 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* background-color: rgba(212, 48, 48, 0.623); */
	/* background-color: #191a1d; */
	background-color: #22212c;
	padding: 2em;
	border-radius: 1em;
}

.about-wrapper .row {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.about-wrapper .row a {
	border: 0.1em solid rgb(146, 144, 144);
	color: white;
	padding: 0.5em;
	transition: border 0.1s ease 0.3s;
}

.about-wrapper .row a:hover {
	border: none;
}
